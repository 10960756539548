<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-warning rounded-lg mb-2">
        <router-link tag="a" to="/store/featured" class="side-nav-link-ref border-0 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"></path>
            <circle cx="10.5" cy="19.5" r="1.5"></circle>
            <circle cx="17.5" cy="19.5" r="1.5"></circle>
          </svg>
          <span class="notranslate">Loja Virtual</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">Painel</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/informatives" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M19.875 3H4.125C2.953 3 2 3.897 2 5v14c0 1.103.953 2 2.125 2h15.75C21.047 21 22 20.103 22 19V5c0-1.103-.953-2-2.125-2zm0 16H4.125c-.057 0-.096-.016-.113-.016-.007 0-.011.002-.012.008L3.988 5.046c.007-.01.052-.046.137-.046h15.75c.079.001.122.028.125.008l.012 13.946c-.007.01-.052.046-.137.046z">
            </path>
            <path d="M6 7h6v6H6zm7 8H6v2h12v-2h-4zm1-4h4v2h-4zm0-4h4v2h-4z"></path>
          </svg>
          <span class="notranslate">Informativos</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/events" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
          </svg>
          <span class="notranslate">Eventos</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/courses" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
            <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
          </svg>
          <span class="notranslate">Cursos</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
            <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
          </svg>
          <span class="notranslate">Rede</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
              <span class="notranslate">Indicados</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/team" class="side-nav-link-ref">
              <span class="notranslate">Equipe</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/binary" class="side-nav-link-ref">
              <span class="notranslate">Binário</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/matriz" class="side-nav-link-ref">
              <span class="notranslate">Matriz</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 21h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM5 5h14l.001 14H5V5z"></path>
            <path d="m13.553 11.658-4-2-2.448 4.895 1.79.894 1.552-3.105 4 2 2.448-4.895-1.79-.894z"></path>
          </svg>
          <span class="notranslate">Relatórios</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/reports/bonus" class="side-nav-link-ref">
              <span class="notranslate">Totais</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/indicacao" class="side-nav-link-ref">
              <span class="notranslate">Indicação</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/equilibrio" class="side-nav-link-ref">
              <span class="notranslate">Equilíbrio</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/ativacao" class="side-nav-link-ref">
              <span class="notranslate">Ativação</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/empreendedor" class="side-nav-link-ref">
              <span class="notranslate">Empreendedor</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/unilevel" class="side-nav-link-ref">
              <span class="notranslate">Unilevel</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/lideranca" class="side-nav-link-ref">
              <span class="notranslate">Liderança</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/graduation" class="side-nav-link-ref">
              <span class="notranslate">Graduação</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M16 12h2v4h-2z"></path>
            <path
              d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM5 5h13v2H5a1.001 1.001 0 0 1 0-2zm15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15v10z">
            </path>
          </svg>
          <span class="notranslate">Financeiro</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z">
            </path>
            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
          </svg>
          <span class="notranslate">Suporte</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/downloads" class="side-nav-link-ref">
              <span class="notranslate">Downloads</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/faq" class="side-nav-link-ref">
              <span class="notranslate">FAQ</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/tickets" class="side-nav-link-ref">
              <span class="notranslate">Tickets</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}

.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}

.py-2 i {
  margin-top: -4px;
}
</style>