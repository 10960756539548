<script>
import { api } from "@/state/services";
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  components: {
    VueSvgGauge
  },
  data() {
    return {
      points: {
        personal: 0,
        used: 0,
        total: 0
      },
      graduations: null,
      previous: {
        id: null,
        name: '',
        points: 0,
        image: null,
      },
      next: {
        id: null,
        name: '',
        points: 0,
        image: null
      },
      progress: 0
    };
  },
  methods: {
    getGraduation() {
      api
        .get('reports/graduation')
        .then(response => {
          if (response.data.status == 'success') {
            this.graduations = response.data.list
            this.points = response.data.graduation.points
            this.previous = response.data.graduation.previous
            this.next = response.data.graduation.next
            this.progress = this.points - this.previous.points

            this.$parent.$parent.graduation.id = response.data.current.id
            this.$parent.$parent.graduation.name = response.data.current.name
            this.$parent.$parent.getStatData();
          }
        })
    }
  },
  created() {
    this.getGraduation();
  }
};
</script>

<template>
  <div class="w-100">
    <div class="d-flex flex-wrap flex-gap-10 mb-4">
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ points.personal | points }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Pessoal</div>
          </div>
        </div>
      </div>
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ points.used | points }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Aproveitado</div>
          </div>
        </div>
      </div>
      <div class="flex-fill">
        <div class="card mb-0">
          <div class="card-body text-center p-2">
            <h5 class="my-1">{{ points.total | points }}</h5>
            <div class="bg-light rounded py-1 font-size-12 text-uppercase">Total</div>
          </div>
        </div>
      </div>
    </div>
    <div style="max-width:400px;" class="m-auto">
      <vue-svg-gauge class="px-3" :start-angle="-110" :end-angle="110" :value="points.used - previous.points +5000" :separator-step="0" :min="Number(0)" :max="Number(next.points - previous.points)"
        baseColor="#CED4DA" gauge-color="#083358" :scale-interval="0" />
    </div>
    <div class="report text-center">
      <h2 class="notranslate">{{ points.used | points }}</h2>
      <div class="d-flex justify-content-around mt-5">
        <div>
          <div class="text-uppercase font-size-12">Atual</div>
          <div class="d-block">
            <img v-if="previous.image" :src="require('@/assets/images/graduations/' + previous.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0 notranslate">{{ previous.points | points }}</h3>
          <p class="m-0 notranslate">{{ previous.name }} </p>
        </div>
        <div>
          <div class="text-uppercase font-size-12">Próximo</div>
          <div class="d-block">
            <img v-if="next.image" :src="require('@/assets/images/graduations/' + next.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0 notranslate">{{ next.points | points }}</h3>
          <p class="m-0 notranslate">{{ next.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report {
  margin-top: -80px;
}

.label {
  line-height: 13px;
}
</style>